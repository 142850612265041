import React from 'react';
import { useState } from 'react';
import { FaFacebookF, FaLinkedinIn, FaWhatsapp, FaPhoneAlt } from 'react-icons/fa'; // For social icons
import './Contact.css';
import Banner from '../landing/Banner';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    Phone_Number: '',
    Company: '',
    Industry_Type: '',
    GST_VAT: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Submit form logic here
    console.log('Form submitted: ', formData);
  };

  return (
    <div>
      <Banner heading={'Get in Touch'} pera={'Reach out to us for any inquiries or collaborations. We’re here to assist you.'} />

      <div className="container mt-5">
        <div className="row align-items-center">
          {/* Left Section */}
          <div className="col-md-6">
            <h2 className="mb-4">Contact Us Today</h2>
            <p>
              At Graven Automation, we value your feedback and inquiries. Whether you have
              questions about our products, need assistance with an order, or want to explore
              partnership opportunities, our team is here to help.
            </p>
            <h5 className="mt-4">Get in Touch with Us</h5>
            <p><strong>Phone:</strong> +91 7316980864</p>
            <p><strong>Email:</strong> info@gravenautomation.com (For General queries) <br />
              sales@gravenautomation.com (for quotations or products related queries)
            </p>
            <p><strong>Address:</strong> Graven Automation Private Limited, <br />
              F38 GROUND FLOOR, <br />
              GANDHI VIHAR NEW DELHI - INDIA - 110009
            </p>
            <h5>Follow Us:</h5>
            <div className="social-icons d-flex mt-3">
              <a href="#" className="me-3"><FaFacebookF style={{ fontSize: '1.5rem', color: '#4267B2' }} /></a>
              <a href="#" className="me-3"><FaLinkedinIn style={{ fontSize: '1.5rem', color: '#0e76a8' }} /></a>
              <a href="#" className="me-3"><FaWhatsapp style={{ fontSize: '1.5rem', color: '#25D366' }} /></a>
              <a href="#"><FaPhoneAlt style={{ fontSize: '1.5rem', color: '#ff5252' }} /></a>
            </div>
          </div>

          {/* Right Section (Form) */}
          <div className="col-md-6">
            <h4 className="mb-4">Drop Us a Message</h4>
            <p>
              For your convenience, you can also reach out to us using the contact form
              below. Please fill in the required fields, and our team will get back to you as
              soon as possible.
            </p>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">Name *</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  placeholder="Enter your name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">Email *</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  placeholder="Enter your email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="Phone_Number" className="form-label">Phone Number *</label>
                <input
                  type="tel"
                  className="form-control"
                  id="Phone_Number"
                  name="Phone_Number"
                  placeholder="Enter your phone number"
                  value={formData.Phone_Number}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="Company" className="form-label">Company *</label>
                <input
                  type="text"
                  className="form-control"
                  id="Company"
                  name="Company"
                  placeholder="Enter your company name"
                  value={formData.Company}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="Industry_Type" className="form-label">Industry Type *</label>
                <input
                  type="text"
                  className="form-control"
                  id="Industry_Type"
                  name="Industry_Type"
                  placeholder="Enter your industry type"
                  value={formData.Industry_Type}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="GST_VAT" className="form-label">GST / VAT *</label>
                <input
                  type="text"
                  className="form-control"
                  id="GST_VAT"
                  name="GST_VAT"
                  placeholder="Enter your GST/VAT"
                  value={formData.GST_VAT}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="message" className="form-label">Message *</label>
                <textarea
                  className="form-control"
                  id="message"
                  name="message"
                  rows="4"
                  placeholder="Enter your message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
              <button type="submit" className="btn btn-danger w-100 py-2">
                Send Message
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
