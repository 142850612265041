import React, { useEffect, useState } from 'react';
import './Index.css'; // Custom CSS for card styling and animations
import Banner from '../landing/Banner';
import Slider from "react-slick";
import AOS from "aos";
import "aos/dist/aos.css";
import Coursal from './Coursal';
import './Testimonials.css'; // Custom CSS for additional styling
import { Link } from "react-router-dom";

const Card = ({ title, description, image }) => {
    const [isHovered, setIsHovered] = useState(false);



    return (
        <div
            className={`card ${isHovered ? 'card-hover' : ''}`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <img src={image} alt={title} />
            <h3>{title}</h3>
            <p>{description}</p>
        </div>
    );
};

const HomeIndex = () => {
    const [slidesToShow, setSlidesToShow] = useState(4); // Default setting

    const products = [
        { name: "Power Supply", image: "https://img.officer.com/files/base/ebm/automationworld/image/2024/01/65a025116bb811001ee1e7d1-automationdirect.png?auto=format,compress&fit=max&q=45&w=640&width=640" },
        { name: "PLC", image: "https://www.m-controls.com/fileadmin/Millenium/Solutions/PLC_Automation/MCS_solutions_PLC-automation_2.jpeg" },
        { name: "VFD", image: "https://m.media-amazon.com/images/I/61wdS0yIRJL.jpg" },
        { name: "HMI", image: "https://www.esa-automation.com/wp-content/uploads/2015/10/hmi_io10-1170x4101.jpg" },
        { name: "SERVO MOTOR", image: "https://www.kebamerica.com/wp-content/uploads/2021/01/Intro-to-servo-motors-for-Industrial-Applications.jpg" },
        { name: "AC DRIVE", image: "https://rsautomation.in/wp-content/uploads/2018/12/800X400ac-drive-min.png" },
        { name: "DC DRIVE", image: "https://5.imimg.com/data5/SELLER/Default/2023/9/348654777/BN/QL/YH/2982993/single-phase-dc-drive-panel-500x500.jpg" },
        { name: "RELAY", image: "https://www.connectortips.com/wp-content/uploads/2020/06/relays-weidmuller.jpg" }
    ];

    useEffect(() => {
        AOS.init({
            once: false,
            //   disable: "phone",
            duration: 1000,
            easing: "ease-out-cubic",
        });
    }, []);
    // Media query change handling
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 600) {
                setSlidesToShow(1);  // Show 1 slide for small screens
            } else if (window.innerWidth < 900) {
                setSlidesToShow(2);  // Show 2 slides for medium screens
            } else if (window.innerWidth < 1200) {
                setSlidesToShow(3);  // Show 2 slides for medium screens
            } else {
                setSlidesToShow(4);  // Show 3 slides for large screens
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Call once to set initial value

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [countryCode, setCountryCode] = useState('');

    useEffect(() => {
        const detectCountry = async () => {
            try {
                // Fetch the user's country information from ipapi.co
                const response = await fetch('https://ipapi.co/json/');
                const data = await response.json();
                const detectedCountry = data.country_code.toUpperCase(); // Convert country code to uppercase
                setCountryCode(detectedCountry); // Set the detected country code
            } catch (error) {

                setCountryCode('IN'); // Fallback to default country (e.g., India)
            }
        };

        detectCountry(); // Run the function on component mount
    }, []);

    const testimonials = [
        {
            quote: "Exceeding Expectations Every Time",
            text: "Graven Automation consistently delivers outstanding results and goes above and beyond to ensure client satisfaction. A truly reliable partner in automation.",
            author: "David Williams",
            image: "https://gravenautomation.com/wp-content/uploads/2023/08/testimonial-skip-10-150x150-1.jpg", // Use a placeholder image for testing
        },
        {
            quote: "Excellent Service",
            text: "Working with Graven Automation has been a game-changer for our business. Their automation solutions have greatly improved our efficiency and productivity.",
            author: "Michael Johnson",
            image: "https://gravenautomation.com/wp-content/uploads/2023/08/testimonial-skip-06.jpg",
        },
        {
            quote: "Highly Recommend!",
            text: "A truly exceptional experience from start to finish A truly exceptional experience from start to finishA truly exceptional experience from start to finish.",
            author: "John Smith",
            image: "https://gravenautomation.com/wp-content/uploads/2023/08/testimonial-skip-03.jpg",
        },
        {
            quote: "Highly Recommend!",
            text: "A truly exceptional experience from start to finish A truly exceptional experience from start to finishA truly exceptional experience from start to finish.",
            author: "John Smith",
            image: "https://gravenautomation.com/wp-content/uploads/2023/08/testimonial-skip-03.jpg",
        },
    ];

    // const [slidesToShow, setSlidesToShow] = useState(4); // Default setting

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 600) {
                setSlidesToShow(1);  // Show 1 slide for small screens
            } else if (window.innerWidth < 900) {
                setSlidesToShow(2);  // Show 2 slides for medium screens
            } else if (window.innerWidth < 1200) {
                setSlidesToShow(3);  // Show 2 slides for medium screens
            } else {
                setSlidesToShow(4) // Show 3 slides for large screens
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Call once to set initial value

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 5000,
        // centerMode: true,         
    };

    const [data, setData] = useState([]);
    const [industrylist, setIndustrylist] = useState([]);
    const [testimonialslist, setTestimonialslist] = useState([]);
    const [brandswedeallist, setBrandswedeallist] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://automation.sai247.in/api/Allcategory/Machine');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const json = await response.json();
                console.log('json', json);

                setData(json.data);
            } catch (err) {
                // setError(err);
            } finally {

            }
        };
        const fetchiIndustrylist = async () => {
            try {
                const response = await fetch('https://automation.sai247.in/api/industrylist');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const json = await response.json();
                console.log('json', json);

                setIndustrylist(json.data);
            } catch (err) {
                // setError(err);
            } finally {

            }
        };
        const fetchiTestimonialslist = async () => {
            try {
                const response = await fetch('https://automation.sai247.in/api/testimonialslist');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const json = await response.json();
                console.log('json', json);

                setTestimonialslist(json.data);
            } catch (err) {
                // setError(err);
            } finally {

            }
        };
        const fetchiBrandswedeallist = async () => {
            try {
                const response = await fetch('https://automation.sai247.in/api/brandswedeallist');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const json = await response.json();
                console.log('json', json);

                setBrandswedeallist(json.data);
            } catch (err) {
                // setError(err);
            } finally {

            }
        };
        fetchData();
        fetchiIndustrylist();
        fetchiTestimonialslist();
        fetchiBrandswedeallist();
    }, []);


    const cards2 = [
        {
            title: 'Marine',
            description: 'Marine.',
            image: 'https://gravenautomation.com/wp-content/uploads/2024/05/Marine.webp',
        },
        {
            title: 'Oil & Gas',
            description: 'Oil & Gas.',
            image: 'https://gravenautomation.com/wp-content/uploads/2024/05/OilGas.webp',
        },
        {
            title: 'Cement',
            description: 'Cement.',
            image: 'https://gravenautomation.com/wp-content/uploads/2024/05/Cement.webp',
        },
        {
            title: 'Manufacturing',
            description: 'Manufacturing.',
            image: 'https://gravenautomation.com/wp-content/uploads/2024/05/Food-Beverage.webp',
        },
        {
            title: 'Life Science',
            description: 'Life Science.',
            image: 'https://gravenautomation.com/wp-content/uploads/2024/05/Life-sciences-manufacturing.webp',
        },
        {
            title: 'Power',
            description: 'Power.',
            image: 'https://gravenautomation.com/wp-content/uploads/2024/05/Power-Generation-Hero.webp',
        },
    ];
    // const [currentNumber, setCurrentNumber] = useState(1);
    const AnimatedCounter = ({ target, duration }) => {
        const [count, setCount] = useState(0); // Initial counter value

        useEffect(() => {
            const start = performance.now(); // Start time of the animation

            const animateCounter = (time) => {
                // Calculate how far along the animation we are
                const progress = (time - start) / duration;

                // Calculate the current value based on progress
                const currentValue = Math.min(target, Math.ceil(progress * target)); // Ceil to nearest integer
                setCount(currentValue); // Update the counter value

                // If we haven't reached the target value, continue animating
                if (progress < 1) {
                    requestAnimationFrame(animateCounter);
                }
            };

            // Start the animation
            requestAnimationFrame(animateCounter);
        }, [target, duration]); // Re-run effect if target or duration changes

        return (
            <div style={{ textAlign: 'center' }}>
                {count == 200 ? count + ' +' : count != 1 ? count : ""}
                {count == 1 ? count + 'MN  +' : ''}
            </div>
        );
    };



    return (
        <div style={{ backgroundColor: '#fff' }}>
            <Banner heading={'Empowering Industry Innovations'} pera={'Leading the way in industrial automation solutions, delivering unparalleled excellence through top-tier products and exceptional service.'} />
            <h2 style={{ textAlign: 'center', marginTop: 50, fontSize: 25 }}>Comprehensive Automation Solutions</h2>
            <p id="Category" style={{ textAlign: 'center' }}>Specializing in PLC, VFD, HMI, Servo Motors, Pneumatics, and Switchgears solutions from renowned global brands to meet specific industry needs.</p>

            {/* <h2 style={{ textAlign: 'center', marginTop: 50 }}>Category</h2> */}






            <section  >
                <div className="products-section" >
                    <h2 style={{ textAlign: 'center', fontSize: 25, }} className="section-title">Products We Deal In</h2>
                    <div style={{ backgroundColor: '#fff', marginTop: 0 }} className="card-container container">
                        <div className='row'  >
                            {data.map((card, index) => (
                                <Link
                                    key={index}
                                    to={`/${countryCode}/category/${card.section_slug}`}

                                    style={{ textDecoration: 'none', marginTop: 10, paddingLeft: 0, paddingRight: 0 }} // Optionally, remove the default link underline
                                    className='col-md-4 col-sm-6 col-lg-3 coloms'
                                >
                                    <div
                                        data-aos="fade-up"
                                        data-aos-duration="3000"
                                        style={{ backgroundColor: '#fff', cursor: 'pointer' }}
                                        className="card2"
                                    >
                                        <img
                                            style={{ backgroundColor: '#fff' }}
                                            src={`https://automation.sai247.in/public/images/sections/category/${card.section_img}`}
                                            alt={'title'}
                                            className="card2-image"
                                        />
                                        <h2 style={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis'
                                        }} className="card2-title">{card.section_name}</h2>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>
            </section>

            {/* <div className="products-section">
                <h2 style={{ textAlign: 'center', fontSize: 25 }} className="section-title">Products We Deal In</h2>
                <div className="products-container">
                    {products.map((product, index) => (
                        <div  key={index} className="product-card">
                            <div className="product-image">
                                <img src={product.image} alt={product.name} />
                            </div>
                            <div className="product-content">
                                <h3 style={{ color: '#000', fontSize: '1em', fontWeight: 'bold', marginTop: 15 }}>{product.name}</h3>
                            </div>
                        </div>
                    ))}
                </div>
            </div> */}


            <section className="aboutSection pb-0">
                <h2 style={{ textAlign: 'center', marginTop: 30, fontSize: 25 }}>Industries We Deal In</h2>
                <div className="containerData container-fluid">
                    <div className='row' style={{ justifyContent: 'center' }}>
                        {industrylist.slice(0, 8).map((card, index) => (
                            <Link
                                key={index}
                                to={`/${countryCode}/Industry/${card.id}`}
                                className="scooter-card col-md-3 col-sm-6 col-lg-3"
                                style={{ textDecoration: 'none', marginTop: 10 }}
                                //  key={index}
                                data-aos="flip-left"
                                data-aos-easing="ease-out-cubic"
                                data-aos-duration="2000"
                            >
                                <div>
                                    <div style={{ backgroundImage: `url(https://automation.sai247.in/public/product/industryimg/${card.photo})` }} className="scooter-image">
                                        <div className="scooter-info">
                                            <h2 style={{ color: '#fff' }}>{card.name}</h2>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
                <div className="explore-more-container">
                    <Link to={`Industries`}>
                        <button className="explore-more-btn">Explore More</button>
                    </Link>
                </div>
            </section>
            {/* <img

                height="340"
                width={'100%'}
                // style={{ marginTop: '20px', height: '40vh' }}
                className='imagebanner'
                alt="Graven Automation"
                src={'https://www.fujitsu.com/lu/imagesgig5/automation-banner-1280x427_tcm67-3844158_tcm67-2750227-32.jpg'}
            /> */}
            <div className="global-pioneer-container">
                <div className="content">
                    <h2>A Global Pioneer in Industrial Automation Parts</h2>
                    <p>You Trusted Automation Supplier</p>
                    <p><strong>Graven Automation collaborates with Industry leaders form around the globe.</strong></p>
                    <div className="stats">
                        <div className="stat">
                            <img src="https://cdn-icons-png.flaticon.com/128/2947/2947656.png" alt="Countries" className="icon" />
                            <p><span><AnimatedCounter target={200} duration={3000} />
                            </span>Countries</p>
                        </div>
                        <div className="stat">
                            <img src="https://cdn-icons-png.flaticon.com/128/10601/10601280.png" alt="Continents" className="icon" />
                            <p><span><AnimatedCounter target={6} duration={5000} /></span>Continents</p>
                        </div>
                        <div className="stat">
                            <img src="https://cdn-icons-png.flaticon.com/128/476/476863.png" alt="Customers" className="icon" />
                            <p><span> <AnimatedCounter target={1} duration={7000} /></span>Customers</p>
                        </div>
                    </div>
                </div>
                <div className="map">
                    <img src="/santanu_1-removebg-preview.png" width='100' height='100' alt="World Map" />
                    {/* <div className="india-highlight"></div> */}
                </div>
            </div>
            {/* <h2 style={{ textAlign: 'center', marginTop: 30 }}>Industries We Deal In</h2>
            <div className="card-container">

                {cards2.map((card, index) => (
                    <Card
                        key={index}
                        title={card.title}
                        description={card.description}
                        image={card.image}
                    />
                ))}
            </div> */}

            <Coursal data={brandswedeallist} />

            <div className="testimonials-section">
                <h2 style={{ textAlign: 'center', marginTop: 0, fontSize: 25 }}>Testimonials Of Success Stories</h2>
                <Slider {...settings}>
                    {testimonialslist.map((testimonial, index) => (
                        <div data-aos="flip-left"
                            data-aos-easing="ease-out-cubic"
                            data-aos-duration="2000"
                            key={index}
                            className="testimonial-card">
                            <img src={`https://automation.sai247.in/public/product/photo_testimonials/${testimonial.photo_testimonials}`} alt={testimonial.author} className="testimonial-image" />
                            <blockquote>
                                <p className='peraa2' >{testimonial.headingname}</p>
                                <p className='peraa' dangerouslySetInnerHTML={{ __html: testimonial.description }}></p>
                                <footer>
                                    {console.log(`https://automation.sai247.in/public/product/photo_testimonials/${testimonial.photo_testimonials}`)
                                    }

                                    <cite>{testimonial.name}</cite>
                                </footer>
                            </blockquote>
                        </div>
                    ))}

                </Slider>
            </div>

            <div className="why-choose-us-section">
                {/* <h2 className="why-choose-us-title">Why Choose Us?</h2>
                <p className="why-choose-us-content">
                    Choosing our business means opting for excellence in automation solutions.
                    We exclusively supply high-quality automation products, ensuring top-tier reliability.
                    Our commitment to authenticity guarantees that you receive only new and genuine products.
                    Experience the efficiency of our services with express delivery, ensuring your automation needs
                    are met promptly and reliably.
                </p> */}
                {/* <div className="map"> */}
                <img src="/WhatsApp Image 2024-11-08 at 3.34.07 PM.jpeg" style={{
                    width: '90vw',
                    height: '100%'
                }} alt="World Map" />
                {/* <div className="india-highlight"></div> */}
                {/* </div> */}
            </div>

        </div>
    );
};

export default HomeIndex;
