// Industries.js
import React, { useEffect, useState } from 'react';
import './Industries.css'; // The updated CSS for overlay effect
import Banner from '../landing/Banner';

const cards2 = [
    {
        title: 'Aerospace',
        description: 'Aerospace.',
        image: 'https://www.js3global.com/wp-content/uploads/2021/05/iStock-1022905536.jpg', // Add the appropriate image URL
    },
    {
        title: 'Automotive & Tire',
        description: 'Automotive & Tire.',
        image: 'https://media.istockphoto.com/id/123066720/photo/tires.jpg?s=612x612&w=0&k=20&c=HOvRabUSHMB3AqWU5QBL_2stAA3ptqGBrLfNvTWG4-Q=', // Add the appropriate image URL
    },
    {
        title: 'Cement',
        description: 'Cement.',
        image: 'https://5.imimg.com/data5/SELLER/Default/2024/6/430924416/YO/OH/HR/19105442/ultratech-ppc-cement-500x500.jpg',
    },
    {
        title: 'Chemical',
        description: 'Chemical.',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS6wkzvlwuXhjySwWHCF4t-lixA3vmJgS950g&s', // Add the appropriate image URL
    },
    {
        title: 'Entertainment',
        description: 'Entertainment.',
        image: 'https://seleritysas.com/wp-content/uploads/2020/10/shutterstock_1149399851.jpg', // Add the appropriate image URL
    },
    {
        title: 'Fiber & Textiles',
        description: 'Fiber & Textiles.',
        image: 'https://textilesschool.com/wp-content/uploads/2022/09/classification-of-fiber.webp', // Add the appropriate image URL
    },
    {
        title: 'Food & Beverage',
        description: 'Food & Beverage.',
        image: 'https://images.credly.com/images/462af983-0597-4bd9-a155-1d33a65692a0/GVSU_Badge_Food_and_Beverage.png', // Add the appropriate image URL
    },
    {
        title: 'Household & Personal Care',
        description: 'Household & Personal Care.',
        image: 'https://www.expertmarketresearch.com/files/images/Top-Household-Care-Products-Manufacturers-in-the-World.jpg', // Add the appropriate image URL
    },
    {
        title: 'Hydrogen',
        description: 'Hydrogen.',
        image: 'https://cdn.sanity.io/images/zlfgolrr/production/1ec2a1229d93afabdc0471d9ac2ecf101c94ef28-3840x2160.jpg?w=3840&h=2160&auto=format', // Add the appropriate image URL
    },
    {
        title: 'Infrastructure',
        description: 'Infrastructure.',
        image: 'https://cdn.cfr.org/sites/default/files/styles/immersive_image_3_2_desktop_2x/public/image/2023/09/Infrastructure_BG_2.webp', // Add the appropriate image URL
    },
    {
        title: 'Life Sciences',
        description: 'Life Sciences.',
        image: 'https://gravenautomation.com/wp-content/uploads/2024/05/Life-sciences-manufacturing.webp',
    },
    {
        title: 'Marine',
        description: 'Marine.',
        image: 'https://gravenautomation.com/wp-content/uploads/2024/05/Marine.webp',
    },
    {
        title: 'Metals',
        description: 'Metals.',
        image: 'https://m.foolcdn.com/media/dubs/images/silver_metal_bars_stacked_atop_one_another.width-880.jpg', // Add the appropriate image URL
    },
    {
        title: 'Mining',
        description: 'Mining.',
        image: 'https://internationalbanker.com/wp-content/uploads/2023/08/Mining.png', // Add the appropriate image URL
    },
    {
        title: 'Oil & Gas',
        description: 'Oil & Gas.',
        image: 'https://gravenautomation.com/wp-content/uploads/2024/05/OilGas.webp',
    },
    {
        title: 'Power Generation',
        description: 'Power Generation.',
        image: 'https://gravenautomation.com/wp-content/uploads/2024/05/Power-Generation-Hero.webp',
    },
    {
        title: 'Print & Publishing',
        description: 'Print & Publishing.',
        image: 'https://thegouldstandard.com/wp-content/uploads/2019/12/printism-publishing-1.jpg', // Add the appropriate image URL
    },
    {
        title: 'Pulp & Paper',
        description: 'Pulp & Paper.',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRkSXjjZwWl26fW_3HmoHw_CJ9qDLM6FWXxDQ&s', // Add the appropriate image URL
    },
    {
        title: 'Semiconductor',
        description: 'Semiconductor.',
        image: 'https://d1whtlypfis84e.cloudfront.net/guides/wp-content/uploads/2021/01/28084352/Sample-1-1024x724.png', // Add the appropriate image URL
    },
    {
        title: 'Warehouse & Fulfillment',
        description: 'Warehouse & Fulfillment.',
        image: 'https://www.shipbob.com/wp-content/uploads/2019/12/iStock-665486066.jpg', // Add the appropriate image URL
    },
    {
        title: 'Waste Management',
        description: 'Waste Management.',
        image: 'https://www.uffizio.com/wp-content/uploads/2024/01/ADAS-25-1-2.jpg', // Add the appropriate image URL
    },
    {
        title: 'Water Wastewater',
        description: 'Water Wastewater.',
        image: 'https://evreka.co/wp-content/uploads/2022/04/Wastewater-treatment-and-disposal-evreka-scaled.jpg', // Add the appropriate image URL
    },
];



const Industries = () => {
    const [industrylist, setIndustrylist] = useState([]);
    useEffect(() => {
        window.scrollTo(0, 0);
        const fetchiIndustrylist = async () => {
            try {
                const response = await fetch('https://automation.sai247.in/api/industrylist');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const json = await response.json();
                console.log('json', json);

                setIndustrylist(json.data);
            } catch (err) {
                // setError(err);
            } finally {

            }
        };

        fetchiIndustrylist();
    }, []);
    return (
        <div>
            <Banner
                heading={'Empowering Industry Innovations'}
                pera={'Leading the way in industrial automation solutions, delivering unparalleled excellence through top-tier products and exceptional service.'}
            />
            <div className="industries-container">
                <h1 className="industries-title">Industries We Deal In</h1>
                <div className="industries-grid">
                    {industrylist.map((card, index) => (
                        <div key={index} className="industry-card">
                            <div
                                className="industry-image"
                                style={{ backgroundImage: `url(https://automation.sai247.in/public/product/industryimg/${card.photo})` }}
                            >
                                <div className="overlay">
                                    <div className="industry-content">
                                        <h2>{card.name}</h2>
                                        {/* <p>{card.description}</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Industries;
