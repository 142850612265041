import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography, RadioGroup, FormControlLabel, Radio, Divider, Avatar, List, ListItem, ListItemText, ListItemIcon, useMediaQuery } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import GiftCardIcon from '@mui/icons-material/CardGiftcard';
import PaymentIcon from '@mui/icons-material/Payment';
import SettingsIcon from '@mui/icons-material/Settings';
import OrderIcon from '@mui/icons-material/ShoppingCart';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { styled } from '@mui/system';
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { useHistory } from 'react-router-dom';
import MyCart from '../mycart/MyCart';
import MyOrders from '../MyOrders/MyOrders';
import EnquiryList from '../Enquiry/EnquiryList';
import axios from 'axios';
import Swal from 'sweetalert2';

const ProfileInfo = ({ firstName, setFirstName, email, setEmail, mobile, setMobile, gender, setGender, isMobile, onUpdate }) => (
  <Box>
    <Typography variant="h5" mb={2}>
      Personal Information <Button startIcon={<EditIcon />}>Edit</Button>
    </Typography>
    <Box mb={2} display="flex" gap={2} flexDirection={isMobile ? 'column' : 'row'}>
      <TextField
        label="First Name"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        fullWidth
      />
    </Box>
    <Box mb={2}>
      <TextField
        label="Email Address"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
        InputProps={{
          // endAdornment: <Button>Edit</Button>,
        }}
      />
    </Box>
    <Box mb={2}>
      <TextField
        label="Mobile Number"
        value={mobile}
        onChange={(e) => setMobile(e.target.value)}
        fullWidth
        InputProps={{
          // endAdornment: <Button>Edit</Button>,
        }}
      />
    </Box>
    <Box mb={2}>
      <Button variant="contained" style={{backgroundColor:'#f43f5e'}} onClick={onUpdate}>
        Update Profile
      </Button>
    </Box>
  </Box>
);

const Sidebar = styled(Box)(({ theme }) => ({
  width: '250px',
  padding: '20px',
  borderRight: '1px solid #ccc',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    padding: '10px',
    borderRight: 'none',
    borderBottom: '1px solid #ccc',
  },
}));

const ProfileContent = styled(Box)( {
  padding: '20px',
  flex: 1,
});

const Profile = () => {
  const isAuthenticated = useIsAuthenticated();
  const auth = useAuthUser();
  const history = useHistory();

  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [gender, setGender] = useState('');
  const [selectedTab, setSelectedTab] = useState('profile');

  useEffect(() => {
    const detectCountry = async () => {
      try {
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        const detectedCountry = data.country_code.toUpperCase();
        if (!isAuthenticated) {
          history.push(`/${detectedCountry}/login`);
        }
      } catch (error) {
        console.error("Error detecting country:", error);
      }
    };
    detectCountry();
  }, [isAuthenticated, history]);

  const isMobile = useMediaQuery('(max-width:900px)');

  const handleProfileUpdate = async () => {
    try {
      const response = await axios.post('https://automation.sai247.in/api/updateProfile', {
        user_id: auth?.uid,
        name: firstName,
        email,
        phone: mobile,
        // Add other fields as necessary
      }, {
        headers: {
          'X-CSRF-Token': 'C30tPucNXm4d6fLdzE0b22YG5kJ2Mg8SMC4wzhnL'
        }
      });
      console.log('Profile updated successfully:', response.data);
      Swal.fire({
        icon: 'success',
        title: 'Profile Updated!',
        text: 'Your profile has been updated successfully.',
        confirmButtonText: 'Okay'
      });
      handleSubmit();
      // Optionally, you can display a success message or update the state
    } catch (error) {
      console.log('Error updating profile:', error);
    }
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post('https://automation.sai247.in/api/UserProfile', {
        user_id: auth?.uid
      }, {
        headers: {
          'X-CSRF-Token': 'C30tPucNXm4d6fLdzE0b22YG5kJ2Mg8SMC4wzhnL'
        }
      });
      setFirstName(response.data.user.name);
      setEmail(response.data.user.email);
      setMobile(response.data.user.phone);
    } catch (error) {
      console.log('Error fetching user data:', error);
    }
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  return (
    <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} style={{ paddingTop: isMobile ? '25%' : '8%', backgroundColor: '#fff', paddingBottom: '5%', width: '100%' }}>
      {/* Sidebar */}
      <Sidebar>
        <Box textAlign="center" mb={4}>
          <Avatar sx={{ width: 56, height: 56, margin: '0 auto' }}> {auth?.name[0]}</Avatar>
          <Typography variant="h6" mt={2}>
            {auth?.name}
          </Typography>
        </Box>
        <List>
          <ListItem button style={{ cursor: 'pointer' }} onClick={() => setSelectedTab('profile')}>
            <ListItemIcon>
              <AccountCircleIcon />
            </ListItemIcon>
            <ListItemText primary="Profile Information" />
          </ListItem>
          <Divider />
          <ListItem button style={{ cursor: 'pointer' }} onClick={() => setSelectedTab('orders')}>
            <ListItemIcon>
              <OrderIcon />
            </ListItemIcon>
            <ListItemText primary="My Orders" />
          </ListItem>
          <Divider />
          <ListItem button style={{ cursor: 'pointer' }} onClick={() => setSelectedTab('enquiry')}>
            <ListItemIcon>
              <OrderIcon />
            </ListItemIcon>
            <ListItemText primary="Enquiry List" />
          </ListItem>
        </List>
      </Sidebar>

      {/* Profile Content */}
      <ProfileContent>
        {/* Render content based on the selected tab */}
        {selectedTab === 'profile' && (
          <ProfileInfo
            firstName={firstName}
            setFirstName={setFirstName}
            email={email}
            setEmail={setEmail}
            mobile={mobile}
            setMobile={setMobile}
            gender={gender}
            setGender={setGender}
            isMobile={isMobile}
            onUpdate={handleProfileUpdate}  // Pass the update handler
          />
        )}
        {selectedTab === 'orders' && <MyOrders />}
        {selectedTab === 'enquiry' && <EnquiryList />}
      </ProfileContent>
    </Box>
  );
};

export default Profile;
