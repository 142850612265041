import { Link } from "react-router-dom";
import "./FeatureProduct.css";
import { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function FeatureProduct({ name, rs, image, variant_id, id }) {
  const [countryCode, setCountryCode] = useState('');

  useEffect(() => {
    const detectCountry = async () => {
      try {
        // Fetch data from ipapi.co
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        const detectedCountry = data.country_code.toUpperCase(); // Get country code and convert to lowercase
        // alert(detectedCountry);

        setCountryCode(detectedCountry);
      } catch (error) {
        console.error("Error detecting country:", error);
        setCountryCode('IN'); // Fallback to a default country (USA)
      } finally {
        // setLoading(false); // Stop loading when the country is detected
      }
    };

    detectCountry();
  }, []);
  useEffect(() => {
    AOS.init({
      once: false,
      //   disable: "phone",
      duration: 1000,
      easing: "ease-out-cubic",
    });
  }, []);
  return (
    <div data-aos="fade-up"
      data-aos-duration="3000" className="col">
      <div className="card feature-card" style={{width:'100%',margin:0}}>
        <img
          className="card-img-top"
          // height=""
          // style={{width:'90%',alignSelf:'center',height:'18vh'}}
          alt={`Image of ${name}`}
          src={`https://automation.sai247.in/public/product/images/${image}`}
        />
        <div className="card-body">
          <h5 className="card-title text-center text-truncate">{name}</h5>
          <p className="card-text text-center text-muted">{rs}</p>
          <div className="d-grid gap-2">
            <Link to={`/${countryCode}/products/${variant_id}/${id}`} className="btn btn-outline-dark custom-btn">
              View Details
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeatureProduct;
