  import Banner from "./Banner";
  import FeatureProduct from "./FeatureProduct";
  import ScrollToTopOnMount from "../template/ScrollToTopOnMount";
  import { useEffect, useState } from "react";
  import axios from "axios";

  function Landing() {
    const [product, setProduct] = useState([]);
    const [countryCode, setCountryCode] = useState('');
    const [searchQuery, setSearchQuery] = useState(''); // Search query state

    useEffect(() => {
      const detectCountry = async () => {
        try {
          const response = await fetch('https://ipapi.co/json/');
          const data = await response.json();
          const detectedCountry = data.country_code.toUpperCase();
          setCountryCode(detectedCountry);
          handleSubmit(detectedCountry);
        } catch (error) {
          console.error("Error detecting country:", error);
          setCountryCode('IN');
        }
      };

      detectCountry();
    }, []);

    const handleSubmit = async (detectedCountry) => {
      try {
        const response = await axios.post('https://automation.sai247.in/api/index', {
          user_id: "",
          countrycode: detectedCountry
        }, {
          headers: {
            'X-CSRF-Token': 'C30tPucNXm4d6fLdzE0b22YG5kJ2Mg8SMC4wzhnL'
          }
        });

        setProduct(response.data.allProducts.data);
      } catch (error) {
        console.log(error);
      }
    };

    // Filter products based on search query
    const filteredProducts = product.filter((item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
      <>
        <div style={{ backgroundColor: '#fff' }}>
          <ScrollToTopOnMount />
          <Banner
            heading={'Empowering Industry Innovations'}
            pera={'Leading the way in industrial automation solutions, delivering unparalleled excellence through top-tier products and exceptional service.'}
          />

          {/* Search Input */}


          <h2 className="text-center mt-4 mb-3">All Products</h2>
          <div className="container my-4" style={{ textAlign: '-webkit-center' }}>
            <input
              type="text"
              className="form-control"
              placeholder="Search products..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)} // Update search query on input change
              style={{ width: '300px', alignSelf: 'center' }}
            />
          </div>
          <div className="container-fluid pb-5 px-lg-5">
            <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4 g-4 px-md-5">
              {filteredProducts.length > 0 ? (
                filteredProducts.map((products, i) => (
                  <FeatureProduct
                    key={i}
                    name={products.name}
                    rs={products.mrp}
                    image={products.image}
                    variant_id={products.variant_id}
                    id={products.id}
                    countryCode={countryCode}
                  />
                ))
              ) : (
                <div className="text-center">No products found</div>
              )}
            </div>
          </div>


        </div>
      </>
    );
  }

  export default Landing;
