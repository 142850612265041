import React, { useState } from 'react';
import { FaFacebookF, FaLinkedinIn, FaWhatsapp, FaPhoneAlt } from 'react-icons/fa'; // For social icons
import Banner from '../landing/Banner';
import './About.css';

function About() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Submit form logic here
        console.log('Form submitted: ', formData);
    };

    return (
        <div>
            <Banner heading={'Leading Automation Solutions'} pera={'Empowering industries through innovative automation'} />

            <div className="container-fluid mt-1">
                <div style={{ marginTop: '2%', marginBottom: '0%' }} className="row">
                <div style={{ padding: '20px' }}>
      {/* Title */}
      <h1>From India to the World – A Journey of Innovation and Growth</h1>
      
      {/* Introduction */}
      <p>
        At Graven Automation, our journey began with a bold vision: to redefine the
        industrial landscape by creating cutting-edge automation solutions and
        products that empower businesses in India and across the globe. From humble
        beginnings in Delhi to building a global presence, we have always been driven
        by a commitment to innovation, excellence, and a relentless focus on solving
        industry challenges.
      </p>
      
      {/* Founder Section */}
      <h2>The Visionary Behind Graven Automation: Our Founder</h2>
      <p>
        Graven Automation was born out of the visionary leadership of Devendra
        Maurya & Kaushal Maurya. With a deep-rooted passion for technology and a
        firm belief in the transformative power of automation, Devendra Maurya laid the
        foundation of the company. Coming from a background that combined
        technical expertise with an entrepreneurial spirit, he saw the need for advanced
        industrial automation in India that would meet global standards.
      </p>
      <p>
        Starting in Delhi, he embarked on a mission to bridge the gap between
        traditional industries and the latest automation technologies. From the outset,
        the goal was clear: to create solutions that not only increase efficiency and
        productivity but also empower businesses to scale and compete on the world
        stage.
      </p>
      
      {/* Quote */}
      <blockquote style={{ fontStyle: 'italic', margin: '20px 0' }}>
        "Innovation isn't just about technology; it’s about creating opportunities for
        growth and transformation in industries that need it the most."
        <br /> – Devendra Maurya
      </blockquote>
      
      <p>
        Mr. Maurya’s vision has always been to build a company that not only leads in
        automation but also contributes to India's industrial growth. With an eye on the
        future, the establishment of Graven Automation’s engine oil manufacturing
        plant in the UPSIDC industrial park in Uttar Pradesh was a direct reflection of
        this ambition—to diversify and provide high-quality industrial solutions that
        meet the growing demands of both Indian and international markets.
      </p>
      
      {/* Evolution Section */}
      <h2>Our Evolution: From Automation to Global Solutions</h2>
      <p>
        What began as a local industrial automation company quickly evolved into a
        multifaceted global enterprise. Our story is one of constant innovation,
        strategic expansion, and building long-lasting relationships with clients across
        industries. Today, we serve a wide range of sectors, from Marine,
        Pharmaceuticals, Cement, Food & Beverages, Oil & Gas, Manufacturing, Pulp &
        Paper, Power, Sugar, Constructions, Logistics, and beyond. Our footprint has
        expanded not only across India but also into international markets, where our
        expertise and solutions are making a tangible impact.
      </p>
      
      {/* Engine Oil Venture Section */}
      <h2>The Engine Oil Venture: Expanding Our Horizons</h2>
      <p>
        In addition to our automation services, our expansion into manufacturing highperformance engine oils represents another significant chapter in our journey.
        Our state-of-the-art facility in Uttar Pradesh stands as a symbol of our
        dedication to quality and sustainability. We take pride in creating products that
        meet the highest standards, ensuring that industries can rely on us for their
        essential operational needs.
      </p>
      
      {/* Innovation Section */}
      <h2>Innovation at the Heart of Our Journey</h2>
      <p>
        Innovation is the core of everything we do. From the start, we have been
        pushing boundaries by integrating advanced technologies like AI, IoT, and
        robotics into our automation solutions. Our team of engineers and technical
        experts work tirelessly to stay ahead of industry trends, delivering tailored
        solutions that drive efficiency and reduce operational costs for our clients.
      </p>
      
      {/* Personalized Approach Section */}
      <h2>A Personalized Approach to Industrial Automation</h2>
      <p>
        While technology is our foundation, our approach is always people-centric.
        Devendra Maurya has built Graven Automation on the principle that every
        business has unique challenges, and therefore, every solution must be
        customized. We engage with our clients closely, ensuring we fully understand
        their needs and goals, and develop automation solutions that not only solve
        immediate problems but also support long-term growth.
      </p>
      
      {/* Journey Ahead Section */}
      <h2>The Journey Ahead: Guided by Our Founder’s Vision</h2>
      <p>
        Our journey is far from over. With Mr. Maurya’s continued leadership and a
        dedicated team by their side, we are exploring new markets and developing
        innovative solutions that will shape the future of industries. The future is full of
        potential, and we are ready to embrace every opportunity that comes our way.
      </p>
      <p>
        Whether through further expansion into international markets, introducing new
        products, or continuing to push the boundaries of automation technology, we
        are committed to staying at the forefront of industrial innovation.
      </p>
      
      {/* Conclusion */}
      <h2>Join Us on Our Journey</h2>
      <p>
        At Graven Automation, we’re not just building solutions; we’re creating a future
        where industries around the world can thrive and grow. Our story is one of
        vision, innovation, and a steadfast commitment to our clients. We invite you to
        be a part of this journey—whether you’re a business looking for automation
        solutions or a partner in our expansion, together, we can shape the industries of
        tomorrow.
      </p>
    </div>
                    {/* Image */}
                    <img

                        height="340"
                        width={'100%'}
                        style={{ borderRadius: 10, marginTop: '20px', height: '40vh' }}
                        alt="Graven Automation"
                        src={'https://gravenautomation.com/wp-content/uploads/2024/05/zipwp-image-5800228.jpeg'}
                    />

                    {/* Button */}
                    {/* <div className="col-12 text-center mt-4">
                        <button
                            className="btn btn-primary btn-lg"
                            onClick={() => alert("Let's Work Together!")}
                        >
                            Let's Work Together!
                        </button>
                    </div> */}

                    {/* Transform Your Business Section */}
                    <div className="col-12 text-center mt-5">
                        <h2>Transform Your Business with Graven Automation</h2>
                        <p>Discover how our innovative solutions can propel your business to the next level.</p>
                    </div>

                    <div className="row text-center mt-4">
                        <div className="col-md-4">
                            <img
                                src="https://www.exin.com/app/uploads/2023/02/Automation-image-for-blog-article.jpg"
                                alt="Tailored Solutions"
                                style={{ width: '100px', height: '100px', marginBottom: '10px' }}
                            />
                            <h4>01. Tailored Solutions</h4>
                            <p>We customize top-tier products to meet your unique requirements.</p>
                        </div>
                        <div className="col-md-4">
                            <img
                                src="https://www.mech-mind.com/Private/NewsImgs/6380525517601540471334021809.webp"
                                alt="Unmatched Service"
                                style={{ width: '100px', height: '100px', marginBottom: '10px' }}
                            />
                            <h4>02. Unmatched Service</h4>
                            <p>Our commitment to exceeding expectations fosters long-term partnerships.</p>
                        </div>
                        <div className="col-md-4">
                            <img
                                src="https://azadmagazine.com/wp-content/uploads/2023/11/Harnessing-the-Power-of-Industrial-Automation-for-Business-Growth.webp"
                                alt="Reliable Expertise"
                                style={{ width: '100px', height: '100px', marginBottom: '10px' }}
                            />
                            <h4>03. Reliable Expertise</h4>
                            <p>With a wealth of experience and a focus on innovation, we deliver trusted solutions.</p>
                        </div>
                    </div>

                    {/* Meet Our Team Section */}
                    <div className="col-12 text-center mt-5">
                        <h2>Meet Our Team</h2>
                    </div>

                    {/* Cards for Team Members */}
                    <div className="row text-center mt-4">
                        <div className="col-md-4">
                            <div className="card" style={{ borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                <img
                                    src="https://gravenautomation.com/wp-content/uploads/2023/08/team-skip-01-1.jpg"
                                    alt="Team Member 1"
                                    className="card-img-top rounded-circle"
                                    style={{ width: '150px', height: '150px', margin: '20px auto' }}
                                />
                                <div className="card-body">
                                    <h5>Shawn Marks</h5>
                                    <p className="card-text">CEO & Founder</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card" style={{ borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                <img
                                    src="https://gravenautomation.com/wp-content/uploads/2023/08/team-skip-02-1.jpg"
                                    alt="Team Member 2"
                                    className="card-img-top rounded-circle"
                                    style={{ width: '150px', height: '150px', margin: '20px auto' }}
                                />
                                <div className="card-body">
                                    <h5>Emma Johnson</h5>
                                    <p className="card-text">CTO</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card" style={{ borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                <img
                                    src="https://gravenautomation.com/wp-content/uploads/2023/08/team-skip-03.jpg"
                                    alt="Team Member 3"
                                    className="card-img-top rounded-circle"
                                    style={{ width: '150px', height: '150px', margin: '20px auto' }}
                                />
                                <div className="card-body">
                                    <h5>John Smith</h5>
                                    <p className="card-text">Head of Operations</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="why-choose-us-section">
                <h2 className="why-choose-us-title">Who we are ??</h2>
                <p className="why-choose-us-content">
                    At Graven Automation, we are at the forefront of transforming industrial operations through innovative automation technologies. Headquartered in New Delhi - INDIA, we have proudly serving the B2B sector with intelligent automation solutions designed to streamline processes, optimize productivity and reduce operational costs.
                    Our journey began with a vision to bridge the gap between traditional industrial practices and modern technologincal advancements. Today, we stand as a trusted partner to industries ranging from manufacturing and loginstics to energy and beyond. By levaraging cutting-edge tools like robotics, IoT, and advanced control systems, we create solutions that not only meet but exceed our clients' expectations.
                    What sets us apart is our unwavering commitment to customization and adaptability. We understand that no two businesses are the same, and our solutions are tailored to the specific challenges and goals of each client. Whether you're looking to enhance production, omprove safety, or minimize downtime, Graven Automation is your partner in achieving sustainable growth through automation.
                    Deriven by innovation, supported by a team of experienced engineers, and backed by a dedication to quality and service, we are redefining what's possible in industrial automation. As industries evolve, so dowe, ensuring that our clients stay ahead in a rapidly changing landscape.
                </p>
            </div>
            <div style={{ marginBottom: '5%' }} className="hero-container">
                <div className="overlay"></div>
                <div className="hero-content">
                    <h1>Transform Your Business with <span>Graven Automation</span></h1>
                    <p>Take the first step towards enhanced efficiency and productivity. Contact us today to explore our cutting-edge automation solutions.</p>
                    <button className="contact-button">Contact Now</button>
                </div>
            </div>
        </div >
    );
}

export default About;
