import React from 'react';

import Banner from '../landing/Banner';

function ShippingDelivery() {

    return (
        <div>
            <Banner heading={'Get in Touch'} pera={'Reach out to us for any inquiries or collaborations. We’re here to assist you.'} />

            <div className="container mt-5">
                <div className="row align-items-center">
                    {/* Left Section */}
                    <div className="col-md-12">
                        <h2 className="mb-12">Shipping and Delivery</h2>
                        <p>

                            DELIVERING ACROSS THE GLOBE
                            At Graven Automation, we ensure swift and reliable deliveries to customers
                            worldwide through our partnerships with trusted couriers, DHL Express and
                            GLS Express. With special agreements in place, we can offer fast and secure
                            shipping to meet your needs. All shipments are fully insured for peace of mind.
                            Please note that customs duties may apply for shipments outside the European
                            Community.
                            For Businesses:
                            When placing an order, please provide Graven Automation with your company
                            name, registered office address, and GSTIN number for accurate billing. The
                            customer is responsible for all costs associated with storage or delivery.
                            Delivery times for products not currently in stock may vary depending on the
                            manufacturer’s or supplier’s timelines.
                            Once your order has been dispatched, you will receive tracking information via
                            email. Please be aware that tracking details may take up to 24 hours to be
                            available after shipment.
                            Graven Automation is not responsible for any delivery delays once the shipment
                            is with the courier.
                            Dispatch Times
                            Indicative dispatch times for products marked as "Available" typically range
                            from 1 to 15 working days. For items labeled "Order Available on Order,"
                            delivery times may vary due to production delays by manufacturers or
                            processing delays from suppliers.
                            Shipments
                            Graven Automation will dispatch your selected products to the address
                            provided during the ordering process, following the procedures outlined above.
                            Deliveries will occur according to the times specified in your order
                            confirmation. Upon receipt, please verify that the delivered products match
                            your order before signing the delivery documents. This verification is essential
                            but does not affect your right of withdrawal, as detailed in our policy.
                            Please note that Graven Automation will not be held liable for any delays or
                            non-delivery of goods due to force majeure events, including but not limited to
                            accidents, explosions, fires, strikes, earthquakes, floods, and any other
                            unforeseen circumstances that may impede the fulfillment of our contractual
                            obligations. We are not liable for any damages, losses, or costs incurred by you
                            or third parties due to such non-performance.
                        </p>


                    </div>

                    {/* Right Section (Form) */}

                </div>
            </div>
        </div>
    );
}

export default ShippingDelivery;
